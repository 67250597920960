/*  ==========================================================================
		PHP Variables
		========================================================================== */

// If you need to access variables such as template url, or AJAX url within this file you can use the variable php_variables.template_url or php_variables.ajax_url
// which has been passed in via enqueue-scripts.php

/*  ==========================================================================
		Grid variables - needed for gridcarousel__init
		========================================================================== */

// NOTE: If your updating this make sure to also update the SASS Variables in _variables.scss
const gridSizes = {
  sm: "550",
  md: "700",
  lg: "1000",
  xl: "1200",
  ws: "1400",
  xw: "1600",
  xxw: "1800",
  xxxw: "2000"
};

/*  ==========================================================================
      DOM element variables 
      ========================================================================== */

const body = document.querySelector("body");

/*  ==========================================================================
		Device Detection - Check if is mobile or ipad
		========================================================================== */

let isMobile = false; // initiate as false
const isTouchDevice = !!("ontouchstart" in window);

if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
  isMobile = true;
}

/*  ==========================================================================
		GENERAL - add linklines to links
		========================================================================== */

const linksInit = function () {
  const links = document.querySelectorAll("a:not(.noline):not(.line--added)");
  links.forEach(function (el) {
    if (el.closest(".noline")) {
      return;
    }

    const icon = el.querySelector(".fa, .fas, .far, .fal, .fab");

    if (el.querySelector("img") < 1) {
      const linkHTML = "<span class='linkline'>" + el.textContent + "</span>";
      el.innerHTML = linkHTML;
      el.classList.add("line--added");
      if (icon) {
        const iconHTML = icon;
        if (icon.classList.contains("fa--left")) {
          el.querySelector(".linkline").before(icon);
        } else {
          el.querySelector(".linkline").after(icon);
        }
      }
    }
  });
  //return true;
}; // linksInit();
linksInit();

/*  ==========================================================================
    GENERAL - sharinglinks
    ========================================================================== */

const sharingLinksInit = function () {
  const links = document.querySelector(".shareiconslist");

  if (!links) {
    return;
  }

  links.addEventListener("click", function (e) {
    e.preventDefault();
    const clicked = e.target.closest(".sharelink--popup");

    if (!clicked) {
      return;
    }

    const shareURL = clicked.getAttribute("href");
    window.open(shareURL, "share-dialog", "width=550,height=436");
  });
}; // sharingLinksInit();
sharingLinksInit();

/*  ==========================================================================
    GENERAL - accordion
    ========================================================================== */

const accordionQuestions = document.querySelectorAll(".accordion__question");

const accordionInit = function (accordionItems) {
  if (!accordionItems) {
    return;
  }

  accordionItems.forEach(item =>
    item.addEventListener("click", function () {
      item.closest(".accordion__item").classList.toggle("accordion__item--open");
    })
  );
}; // accordionInit();
accordionInit(accordionQuestions);

/*  ==========================================================================
    GENERAL - LazyLoad - Enable Lazy load for images
    ========================================================================== */

// Lazy load images (or everything with .lazy)
const lazyLoadInstance = new LazyLoad();

/*  ==========================================================================
    GENERAL - Initiate plyr (custom video controls)

    This is automatically disabled on builds, to enable uncomment the JS here
    and then go to functions / enqueue-scripts and enqueue-styles to uncomment and enable the css and JS 

    ========================================================================== */

// const plyrsInit = function () {
//   const plyrs = document.querySelectorAll(".mediavideo:not(.mediavideo--autoplay)");

//   if (!plyrs) {
//     return;
//   }

//   plyrs.forEach(function (el) {
//     let plyr = el.querySelector(".mediavideo__video");
//     new Plyr(plyr, {
//       controls: ["play", "progress", "mute", "current-time"],
//       hideControls: true,
//       invertTime: false,
//       tooltips: { controls: false, seek: false }
//     });
//   });
// }; // plyrsInit();

// plyrsInit();

/*  ==========================================================================
    AJAX - AJAX load more / paging
    ========================================================================== */

const loadmoreBtn = document.querySelector(".paging__loadmore");
const loadmoreContainer = document.querySelector(".loadmore-container");

const loadmoreLoadPosts = function () {
  const button = this;
  const data = {
    action: "loadmore",
    query: php_variables.posts, // that's how we get params from wp_localize_script() function
    page: php_variables.current_page
  };
  let buttonText; // create variable to store button text

  // This AJAX triggers a PHP hook within function-hooks.php called ajax_loadmore_handler
  $.ajax({
    url: php_variables.ajax_url, // AJAX handler
    data: data,
    type: "POST",
    beforeSend: function (xhr) {
      buttonText = button.textContent; // store current button text
      button.textContent = "Loading..."; // change the button text, to let the user know we are loading more posts
    },
    success: function (data) {
      if (data) {
        button.textContent = buttonText; // set back to previous button text
        loadmoreContainer.innerHTML = loadmoreContainer.innerHTML + data; // insert new posts to the loadmore-container, this should be the row that includes the posts
        php_variables.current_page++;
        // if last page, remove the button..
        if (parseInt(php_variables.current_page) === parseInt(php_variables.max_page)) {
          button.remove();
        }
        // Re-init links to make sure linklines on newly loaded content still work
        linksInit();
        // Re-init lazy load
        lazyLoadInstance.update(); // reinit lazy load after clones
        // Re-init effects
        effectsInit();
      } else {
        button.remove(); // if no data, remove the button as well
      }
    }
  });
}; // loadmoreLoadPosts();

const loadmoreInit = function () {
  if (!loadmoreBtn) {
    return;
  }

  loadmoreBtn.addEventListener("click", loadmoreLoadPosts);
}; // loadmoreInit();
loadmoreInit();

/*  ==========================================================================
    Scroll effects
    ========================================================================== */

const effects = document.querySelectorAll(".effect");

const effectsInit = function () {
  if (!effects) {
    return;
  }

  effects.forEach(function (val) {
    const waypoints = new Waypoint({
      element: val,
      handler: function (direction) {
        if (direction === "down") {
          val.classList.add("effect--ready");
        } else {
          val.classList.remove("effect--ready");
        }
      },
      offset: "90%"
    });
  });
}; // effectsInit();
effectsInit();

/*  ==========================================================================
			Grid Carousel (Currently used on Featured Posts & Featured People)
			========================================================================== */

const gridCarousels = document.querySelectorAll(".row--carousel .owl-carousel");

const gridCarouselInit = function (gridCarousels) {
  if (gridCarousels.length <= 0) {
    return;
  }

  gridCarousels.forEach(elem => {
    const slides = elem.querySelectorAll(".col");
    if (!slides) {
      return;
    }

    const responsiveArray = carouselResponsiveArray(elem);

    // Optional data attribute settings
    const autoplay = elem.dataset.autoplay ? elem.dataset.autoplay : false;
    const autoplayTimeout = elem.dataset.autoplayTimeout ? elem.dataset.autoplayTimeout : 5000;
    const loop = elem.dataset.loop ? elem.dataset.loop : false;
    const nav = elem.dataset.nav ? elem.dataset.nav : false;
    const dots = elem.dataset.dots ? elem.dataset.dots : false;
    // Default for autoheight is "true", so to turn off you need to set the data-attribute to "disable"
    const autoHeight = elem.dataset.autoHeight && elem.dataset.autoHeight === "disable" ? false : true;

    $(elem).owlCarousel({
      autoplay: autoplay,
      autoplayTimeout: autoplayTimeout,
      loop: loop,
      nav: nav,
      dots: dots,
      items: 1,
      navText: ["<i class='far fa-arrow-left'></i>", "<i class='far fa-arrow-right'></i>"],
      responsive: responsiveArray,
      startPosition: slides.length,
      autoHeight: autoHeight
    });

    $(elem).on("resized.owl.carousel", function (event) {
      // Refresh waypoints on resize with slight delay - bit of a hack but stop its breaking waypoint animations
      setTimeout(function () {
        Waypoint.refreshAll();
      }, 500);
    });
  });
}; // gridCarouselInit();
gridCarouselInit(gridCarousels);

/*  ==========================================================================
    Grid Carousel - Create responsive array 
    ========================================================================== */

function carouselResponsiveArray(elem) {
  let responsiveArray = {};
  const slides = elem.querySelectorAll(".col");
  const classes = slides[0].classList;

  classes.forEach((cl, index) => {
    if (cl.indexOf("col-") !== -1) {
      let className = cl;
      let classWidth = 0;
      let numItems = 4;
      className = cl.replace("col-", "");
      // get min width from col class
      const classGridsize = className.split("-")[0];
      // check this is a string not a number, this will remove up cols that dont have a grid size e.g. col-1-2
      if (isNaN(classGridsize)) {
        className = className.replace(classGridsize + "-", "");
        classWidth = gridSizes[classGridsize];
      } else {
        classWidth = 0;
      }
      // split rest of class into sections (this should now be something like '1-4')
      const otherClassParts = className.split("-");
      // divide second number (e.g 4) by first number (e.g 1) to get number of items
      if (otherClassParts[1]) {
        numItems = otherClassParts[1] / otherClassParts[0];
      } else {
        numItems = 1; // if there isn't a second number it must be a single grid
      }
      responsiveArray[classWidth] = {
        items: numItems
      };
    }
  });

  return responsiveArray;
} // carouselResponsiveArray();

/*  ==========================================================================
	NAVIGATION - mobnav
	========================================================================== */

const mobnav = document.querySelector(".mobnav");
const siteHeaderBd = document.querySelector(".siteheader__bd");
const mobnavToggle = document.querySelector(".mobnav-toggle");
const mobnavOpenclass = "mobnavopen";

const mobnavSetHeight = function () {
  const mobnavHeight = window.innerHeight - siteHeaderBd.getBoundingClientRect().height;
  mobnav.style.height = `${mobnavHeight}px`;
}; // mobnavSetHeight()

const mobnavInit = function () {
  // Open mobnav on click the toggle
  mobnavToggle.addEventListener("click", function (e) {
    e.preventDefault();

    let mobnavHeight = 0;
    if (!body.classList.contains(mobnavOpenclass)) {
      // IT'S NOT OPEN...
      mobnavSetHeight();
      body.classList.add(mobnavOpenclass);
    } else {
      // IT'S OPEN...
      mobnav.style.height = 0;
      body.classList.remove(mobnavOpenclass);
    }
  });
}; // mobnavInit()
mobnavInit();

// On resize check mobnav height is still right
const mobnavResize = function () {
  let mobnavHeight = 0;
  if (body.classList.contains(mobnavOpenclass)) {
    mobnavSetHeight();
  }
}; // mobnavResize()

window.addEventListener(
  "resize",
  function (event) {
    mobnavResize();
  },
  true
);

/*  ==========================================================================
      NAVIGATION - mobnav dropdowns
      ========================================================================== */

const mobnavLinks = document.querySelectorAll(".mobnav .sitenav__item--hasdropdown .sitenav__item__dropdownarrow");

const mobnavlinksInit = function () {
  if (!mobnavLinks) {
    return;
  }

  mobnavLinks.forEach(function (mobnavLink) {
    mobnavLink.addEventListener("click", function (e) {
      e.preventDefault();
      const mobnavTargetSection = e.target.closest(".sitenav__item");
      mobnavTargetSection.classList.toggle("sitenav__item--open");
    });
  });
}; // mobnavlinksInit();
mobnavlinksInit();

/*  ==========================================================================
	NAVIGATION - Search
	========================================================================== */

// Search open & close
const searchNav = document.querySelector(".siteheader__search");
const searchToggle = document.querySelector(".search-toggle");
const searchInput = document.querySelector(".siteheader__search .searchform__input input");

// Close search modal when clicked elsewhere in the document
const clickedOusideSearch = function (e) {
  if (!searchNav.classList.contains("siteheader__search--open")) {
    return;
  }

  var isClickInsideElement =
    searchNav.contains(e.target) || searchToggle === e.target || searchToggle.contains(e.target);

  if (!isClickInsideElement) {
    searchNav.classList.remove("siteheader__search--open");
    body.removeEventListener("mouseup", clickedOusideSearch, false);
  }
}; // clickedOusideSearch();

// Toggle search modal window
const searchModalToggle = function (e) {
  e.preventDefault();
  if (searchNav.classList.contains("siteheader__search--open")) {
    searchNav.classList.remove("siteheader__search--open");
    body.removeEventListener("mouseup", clickedOusideSearch, false);
  } else {
    searchNav.classList.add("siteheader__search--open");
    searchInput.focus();
    body.addEventListener("mouseup", clickedOusideSearch, false);
  }
}; // searchModalToggle();

const searchModalInit = function () {
  if (!searchNav) {
    return;
  }
  searchToggle.addEventListener("click", searchModalToggle);
}; // searchModalInit();

searchModalInit();

/*  ==========================================================================
  Polyfill IE11 for forEach
  ========================================================================== */

  if ("NodeList" in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
